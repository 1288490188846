<template>
  <div>
    <div class="banner" >
      <b-container style="height: 100%; position: relative">
        <b-row>
          <b-col cols="12" class="banner-top mb-3 mb-xl-5">
            <h5 class="text-light text-center mt-1 pt-2 tagline text-uppercase d-none d-s d-xl-none">
              For Artists
            </h5>
          </b-col>
        </b-row>
        <b-row class="">
          <b-col cols="12 px-sm-0">
            <b-row class="justify-content-center headline-row">
              <b-col
                cols="10"
                offset="0"
                sm="9"
                md="12"
                offset-md="0"
                class="mt-3 mt-sm-0 px-0 pt-md-1 pt-lg-5 mx-md-5 mx-lg-0 px-md-5 px-lg-0"
              >
                <h1 class="text-light headline text-center mt-3 pt-0 mt-sm-5 pt-sm-5 pt-md-4 mx-md-5 mx-lg-0 px-md-3 px-lg-0">
                  More Than Just Streams
                </h1>
              </b-col>
            </b-row>
            <b-row class="mt-0 mt-sm-4 mt-md-5 mt-xl-4 pt-sm-2 pt-md-0 pt-xl-4 justify-content-center">
              <b-col
                cols="12"
                offset="0"
                sm="10"
                md="12"
                offset-md="0"
                class="text-center px-0"
              >
                <h3 class="text-light sub-heading mt-2 mt-sm-2 mt-md-4 mt-lg-1 mt-xl-0 px-4 px-sm-0 px-md-4 mx-2 mx-md-5 mb-xl-3">
                  Top Artists can earn {{ symbol }}100,000+<br class="d-none d-md-block d-lg-none"/> a month on Trackd.
                </h3>

                <h5
                  class="text-light mt-0 mt-sm-3 mt-xl-3 mt-sm-4 pt-xl-1 pt-2 pt-sm-0 px-4 px-sm-2 px-md-0"
                  :class="{ 'sub-text': $route.name !== 'LandingB', 'sub-text-alt': $route.name === 'LandingB',}"
                >
                  Upload, set your subscription<br class=" d-sm-block d-md-none"/> price and share. <br class="d-sm-none d-md-block" />
                  Finally, get paid fairly for your music and content.
                </h5>

                <template v-if="$route.name === 'LandingB'">

                  <b-button href="#" @click="redirectToSite" class=" d-sm-block custom-btn text-light mt-5 mb-4 px-5 py-2"
                    :class="{ 'custom-btn-alt': $route.name === 'LandingB'}"
                    >Start Earning Now</b-button
                  >

                  <a href="#run-the-numbers" v-smooth-scroll class="d-block text-light no-underline fw-900"
                    :class="{ 'fs-14': $route.name === 'LandingB' }"
                    >or Find out more below</a
                  >

                  <a href="#run-the-numbers" v-smooth-scroll class="d-block">
                    <img src="../../assets/img/arrow-down.svg" class="my-3 down-arrow" />
                  </a>

                  <p
                    class="made-by text-light mb-1 mb-sm-0"
                    :class="{ 'mt-0': $route.name === 'LandingB', 'mt-3': $route.name !== 'LandingB' }"
                  >
                    Made for Artists, by Artists.
                  </p>
                </template>

              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>

export default {
  name: "Landing",
  props: ['symbol'],
  components: {},
  methods: {
    redirectToSite() {
      var url_append = ''

      if (
        this.$route.query.utm_campaign &&
        this.$route.query.utm_source &&
        this.$route.query.utm_medium
      ) {

        url_append += '&utm_campaign=' + this.$route.query.utm_campaign
        url_append += '&utm_source=' + this.$route.query.utm_source
        url_append += '&utm_medium=' + this.$route.query.utm_medium

      }

      window.location = process.env.VUE_APP_REDIRECT_URL + url_append


    }
  },
  computed: {
  },
  watch: {},
};
</script>

<style scoped>

.banner {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 95%), url("../../assets/img/banner/background-03.jpg");
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 42vh;
  /* height: 100vh; */
  /* Center and scale the image nicely */
  background-position: unset;
  background-repeat: no-repeat;
  background-size: 113% 110%;
  max-height: 800px;
}

.tagline {
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
}

.headline {
  font-weight: 200;
  font-size: 42px;
  line-height: 42px;
  text-align: center;
  margin-bottom: 0;
}

.sub-heading {
  font-weight: 900;
  line-height: 26px;
  text-align: center;
  margin-bottom: 0;
  font-size: 22px;
}

.sub-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.sub-text-alt {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.no-underline {
  text-decoration: none;
}

.custom-btn {
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
}

.custom-btn-alt {
  width: 231px;
  height: 37px;
  line-height: 20px;
}

.made-by {
  font-weight: 300 !important;
  font-size: 14px;
  line-height: 30px;
  text-decoration-line: none !important;
}

.down-arrow {
  width: 25px;
}

.section p.large {
  font-size: 24px;
}

.section p.small {
  font-size: 18px;
}

#run-the-numbers.section .chipinplus-logo  {
  height: 28px;
}

.fw-900 {
  font-weight: 900 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-100 {
  font-weight: 100 !important;
}

.info-headline {
  font-weight: 900;
  line-height: 40px;
  margin-bottom: 0;
  font-size: 40px;

}

.rounded-10 {
  border-radius: 10px;
}

.rounded-12 {
  border-radius: 12px;
}

.fs-40 {
  font-size: 40px;
}

.fs-24 {
  font-size: 24px;
}

.fs-22 {
  font-size: 22px;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.fs-16 {
  font-size: 16px;
}

.fs-14 {
  font-size: 14px;
}

.f-r {
  float: right !important;
}

@media (min-width: 320px) {

  .banner {
    height: auto;
  }

}
@media (min-width: 576px) {

  .banner {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 95%), url("../../assets/img/banner/background-03.jpg");
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 90vh;
    /* Center and scale the image nicely */
    background-position: -72px -242px;
    background-repeat: no-repeat;
    background-size: 236% 142%;
    max-height: 800px;
  }

  .headline {
    font-weight: 200;
    font-size: 73px;
    line-height: 75px;
    text-align: center;
    margin-bottom: 0;
    transform: scale(1.07);
  }

  .headline-row {
    
  }

  .sub-heading {
    font-weight: 900;
    line-height: 40px;
    text-align: center;
    margin-bottom: 0;
    font-size: 32px;
  }

  .sub-text {
    font-size: 24px;
    line-height: 30px;
    font-weight: 900;
  }

  .sub-text-alt {
    font-size: 18px;
    line-height: 30px;
    font-weight: 900;
  }
}

@media (min-width: 768px) {

  .banner {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 95%), url("../../assets/img/banner/background-03.jpg");
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 120vh;
    /* Center and scale the image nicely */
    background-position: -73px -229px;
    background-repeat: no-repeat;
    background-size: 177%;
    max-height: 800px;
  }

  .headline {
    font-weight: 200;
    font-size: 80px;
    line-height: 80px;
    text-align: center;
    margin-bottom: 0;
    transform: none;
  }

  .headline-row {
    margin-left: none;
    margin-right: none;
  }

  .sub-heading {
    font-weight: 900;
    line-height: 40px;
    text-align: center;
    margin-bottom: 0;
    font-size: 32px;
  }

  .sub-text {
    font-size: 24px;
    line-height: 30px;
    font-weight: 900;
  }

  .sub-text-alt {
    font-size: 24px;
    line-height: 30px;
    font-weight: 900;
  }
}

@media (min-width: 992px) {

  .banner {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 95%), url("../../assets/img/banner/background-03.jpg");
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 200vh;
    /* Center and scale the image nicely */
    /* background-position: center; */
    background-repeat: no-repeat;
    /* background-size: cover; */
    max-height: 800px;

    background-position: -72px 0px;
    background-size: 137% 108%;
  }

  .headline {
    font-weight: 200;
    font-size: 70px;
    line-height: 70px;
    text-align: center;
    margin-bottom: 0;
  }

  .headline-row {
    margin-top: 25px;
  }

  .sub-heading {
    font-weight: 900;
    line-height: 40px;
    text-align: center;
    margin-bottom: 0;
    font-size: 32px;
  }

  .sub-text {
    font-size: 24px;
    line-height: 30px;
    font-weight: 900;
  }

  .sub-text-alt {
    font-size: 24px;
    line-height: 30px;
    font-weight: 900;
  }
}

@media (min-width: 1200px) {

  .banner {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 95%), url("../../assets/img/banner/background-03.jpg");
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 200vh;
    /* Center and scale the image nicely */
    /* background-position: inherit; */
    background-repeat: no-repeat;
    /* background-size: cover; */
    max-height: 800px;

    /* Center and scale the image nicely */
    background-position: -71px  0px;
    background-size: 113%;
    
  }

  .headline {
    font-weight: 200;
    font-size: 80px;
    line-height: 70px;
    text-align: center;
    margin-bottom: 0;
  }

  .headline-row {
    margin-top: 0px;
  }

  .sub-heading {
    font-weight: 900;
    line-height: 40px;
    text-align: center;
    margin-bottom: 0;
    font-size: 30px;
  }

  .sub-text {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
  }
}

@media (min-width: 2560px) {
  .banner {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 95%), url("../../assets/img/banner/background-03.jpg");
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 100vh;
    /* Center and scale the image nicely */
    /* background-position: inherit; */
    background-repeat: no-repeat;
    /* background-size: cover; */
    max-height: 800px;

    /* Center and scale the image nicely */
    background-position: top;
    background-size: 100%;
    
  }

  .headline {
    font-weight: 200;
    font-size: 100px;
    line-height: 70px;
    text-align: center;
    margin-bottom: 0;
  }

  .headline-row {
    margin-top: -50px;
  }
}

</style>
